<template>
	<div class="login">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<v-card elevation="0" width="900" color="transparent" class="mx-auto"
			:class="$vuetify.breakpoint.xsOnly ? 'px-0 mx-0' : 'px-0'"
			>
			<v-card elevation="8" style="" class="container glassmorphism" id="container"
				:class="panelActive ? 'right-panel-active' : ''">
				<v-card elevation="0" tile color="transparent" class="form-container sign-up-container">
					<v-form ref="form2" :class="$vuetify.breakpoint.xsOnly ? 'pa-2' : 'pa-4'" style="width: 100%" :style="$vuetify.theme.dark ? 'background-color: #1E1E1E;' : 'background-color: white;'">
						<v-row>
							<v-col cols="12" class="mt-3">
								<h1 v-if="$vuetify.breakpoint.smAndUp">S'inscrire</h1>

								<p v-if="$vuetify.breakpoint.xsOnly" class="font-weight-bold text-h5">S'inscrire</p>
							</v-col>

							<v-col cols="12">
								<v-text-field filled type="email" v-model="email" placeholder="Email" :rules="this.$fieldsRules.email.concat(this.$fieldsRules.required('email'))" />
								<v-text-field filled type="password" v-model="password" placeholder="Mot de passe" :rules="this.$fieldsRules.required('mot de passe')" />
								<v-text-field filled type="password" v-model="confpassword" placeholder="Confirmation du mot de passe" :rules="this.$fieldsRules.password(password).concat(this.$fieldsRules.required('confirmation du mot de passe', true))" />
							</v-col>

							<v-col cols="12">
								<v-btn class="mt-2" @click="preSignUp()" :color="$vuetify.theme.dark ? 'white' : 'black'" outlined>M'inscrire</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-card>

				<v-card dark tile elevation="0" class="form-container sign-in-container" color="transparent">
					<v-col>
						<h1 class="mt-16 mb-8 text-center black--text">Login</h1>
						<!-- <span>or use your account</span> -->
						<v-form ref="form" class="pa-0 ma-0"
							style="background: none; background-color: transparent; width: 100%; color: black">

							<input v-model="email" type="email" placeholder="Email" :rules="this.$fieldsRules.email.concat(this.$fieldsRules.required('email'))" label="E-mail" required />

							<input type="password" placeholder="Mot de passe" v-model="password" :rules="this.$fieldsRules.required('mot de passe')" label="Mot de passe" required />

							<template v-if="needOTP">
								<p>Entrez le code OTP généré par Google Authenticator.</p>
								<v-otp-input length="6" type="number" v-model="otpcode" @finish="checkOTP()"></v-otp-input>
							</template>
						</v-form>

						<v-row>
							<v-col cols="12" class="mb-0">
								<v-card-title>
									<v-spacer></v-spacer>
									<v-btn color="black" outlined @click="login()" v-if="!needOTP && $vuetify.breakpoint.smAndUp">
										Se connecter
									</v-btn>

									<v-btn color="black" outlined block @click="login()" v-if="!needOTP && $vuetify.breakpoint.xsOnly">
										Se connecter
									</v-btn>

									<v-spacer></v-spacer>
								</v-card-title>
							</v-col>
							<v-col cols="12" class="pa-0" v-if="$vuetify.breakpoint.smAndUp && !needOTP">
								<v-card-title class="pa-0">
									<v-spacer></v-spacer>
									<v-btn color="black" text small @click="dialogs.resetPassword = true">
										J'ai oublié mon mot de passe :(
									</v-btn>
									<v-spacer></v-spacer>
								</v-card-title>
							</v-col>
						</v-row>
					</v-col>
				</v-card>

				<v-card tile elevation="0" class="overlay-container" color="transparent">
					<div class="overlay primary">
						<v-card tile class="overlay-panel overlay-left px-4">
							<h1 class="mb-6" v-if="$vuetify.breakpoint.smAndUp">Welcome Back, Commander.</h1>
							<p class="mb-6 text-h6 font-weight-bold" v-if="$vuetify.breakpoint.xsOnly">Welcome Back,
								Commander.</p>

							<p class="mb-4 mb-12">Si vous avez déjà un compte, c'est par ici !</p>

							<v-btn @click="panelActive = 0" :color="$vuetify.theme.dark ? 'white' : 'black'" outlined>
								Se connecter
							</v-btn>
						</v-card>
						<v-card tile class="overlay-panel overlay-right px-2">
							<h1 class="mb-12">Bonjour !</h1>
							<p class="mb-12" style="font-size: 1em;">Prêt à changer la donne ?</p>
							<v-btn @click="panelActive = 1" outlined :color="$vuetify.theme.dark ? 'white' : 'black'">
								M'inscrire !</v-btn>
						</v-card>
					</div>
				</v-card>




			</v-card>

			<v-col cols="12" class="pa-0 mt-4" v-if="$vuetify.breakpoint.xsOnly">
				<v-card-title class="pa-0">
					<v-spacer></v-spacer>
					<v-btn text small @click="dialogs.resetPassword = true"
						:color="$vuetify.theme.dark ? 'black' : 'black'">
						J'ai oublié mon mot de passe :(
					</v-btn>
					<v-spacer></v-spacer>
				</v-card-title>
			</v-col>

		</v-card>
		<!-- <v-row justify="center" class="mt-5">
			<v-col cols="12" sm="6">
				<v-card>
					<v-card-text>
						<v-form ref="form">
							<v-text-field v-model="email"
								:rules="this.$fieldsRules.email.concat(this.$fieldsRules.required('email'))" label="E-mail"
								required></v-text-field>
							<v-text-field v-model="password" :rules="this.$fieldsRules.required('mot de passe')"
								type="password" label="Mot de passe" required></v-text-field>
						</v-form>
					</v-card-text>

					<v-divider class="my-1"></v-divider>

					<v-card-actions>
						<v-btn text small @click="dialogs.resetPassword = true">
							J'ai oublié mon mot de passe
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="primary" text @click="login()">
							Se connecter
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row> -->

		<v-dialog v-model="dialogs.resetPassword" max-width="500px">
			<v-card>
				<v-card-title class="headline">
					Récupérez votre compte
				</v-card-title>
				<v-card-text>
					<p>Entrez l'adresse e-mail de votre compte pour le récupérer.<br />Un mail de réinitialisation de
						mot de passe vous sera envoyé.</p>
					<v-form ref="form2" style="background: transparent;">
						<v-text-field style="width: 100%;" v-model="email"
							:rules="this.$fieldsRules.email.concat(this.$fieldsRules.required('email'))" label="E-mail"
							required></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn :color="$vuetify.theme.dark ? 'white' : 'black'" outlined @click="resetPassword()">
						Envoyer
					</v-btn>
					<v-btn color="success" text @click="dialogs.resetPassword = false">
						Annuler
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>




		<!-- Dialog Begin -->
		<v-dialog v-model="dialogs.register" fullscreen hide-overlay transition="dialog-bottom-transition"
			style="background-color: transparent!important; background: none!important;">
			<v-card class="glassmorphism">
				<v-card-title>
					<!-- <span class="headline">Inscription</span> -->
					<v-spacer></v-spacer>
					<v-btn class="ma-2 mt-4" elevation="8" icon outlined :x-large="$vuetify.breakpoint.mobile"
						:color="$vuetify.theme.dark ? 'white' : 'white'" @click="dialogs.register = false">
						<v-icon>mdi-arrow-down</v-icon>
					</v-btn>
				</v-card-title>

				<Register />

			</v-card>
		</v-dialog>
		<!-- Dialog End -->




	</div>
</template>


<style scoped>
	body {
		background: #f6f5f7;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-family: 'Montserrat', sans-serif;
		height: 100vh;
		margin: -20px 0 50px;
	}


	a {
		color: #333;
		font-size: 14px;
		text-decoration: none;
		margin: 15px 0;
	}

	form {
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 50px;
		height: 100%;
		text-align: center;
	}

	input {
		background-color: #eee;
		border: none;
		padding: 12px 15px;
		margin: 8px 0;
		width: 100%;
	}

	.container {
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
			0 10px 10px rgba(0, 0, 0, 0.22);
		position: relative;
		overflow: hidden;
		width: 768px;
		max-width: 100%;
		min-height: 480px;
	}

	.form-container {
		position: absolute;
		top: 0;
		height: 100%;
		transition: all 0.6s ease-in-out;
	}

	.sign-in-container {
		left: 0;
		width: 50%;
		z-index: 2;
	}

	.container.right-panel-active .sign-in-container {
		transform: translateX(100%);
	}

	.sign-up-container {
		left: 0;
		width: 50%;
		opacity: 0;
		z-index: 1;
	}

	.container.right-panel-active .sign-up-container {
		transform: translateX(100%);
		opacity: 1;
		z-index: 5;
		animation: show 0.6s;
	}

	@keyframes show {

		0%,
		49.99% {
			opacity: 0;
			z-index: 1;
		}

		50%,
		100% {
			opacity: 1;
			z-index: 5;
		}
	}

	.overlay-container {
		position: absolute;
		top: 0;
		left: 50%;
		width: 50%;
		height: 100%;
		overflow: hidden;
		transition: transform 0.6s ease-in-out;
		z-index: 100;
	}

	.container.right-panel-active .overlay-container {
		transform: translateX(-100%);
	}

	.overlay {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 0;
		color: #FFFFFF;
		position: relative;
		left: -100%;
		height: 100%;
		width: 200%;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
	}

	.container.right-panel-active .overlay {
		transform: translateX(50%);
	}

	.overlay-panel {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		top: 0;
		height: 100%;
		width: 50%;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
	}

	.overlay-left {
		transform: translateX(-20%);
	}

	.container.right-panel-active .overlay-left {
		transform: translateX(0);
	}

	.overlay-right {
		right: 0;
		transform: translateX(0);
	}

	.container.right-panel-active .overlay-right {
		transform: translateX(20%);
	}

	.social-container {
		margin: 20px 0;
	}

	.social-container a {
		border: 1px solid #DDDDDD;
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin: 0 5px;
		height: 40px;
		width: 40px;
	}

</style>



<script>
	import Head from '@/components/graphics/head'
	import Register from '@/components/forms/register'
	const {
		verify
	} = require('2fa-util');

	export default {
		name: "Access",
		head: {
			title: {
				inner: "Connexion"
			}
		},
		components: {
			Head,
			Register,
		},
		data: () => ({
			email: '',
			password: '',
			confpassword: '',

			panelActive: 0,

			needOTP: false,
			otpcode: '',
			otpsecret: '',
			otpbackup: [],

			Head: {

				Title: 'Connexion',
				baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
				TitleWeight: 'thin', // thin / light / regular / medium / bold / black
				TitleColor: 'white', // white / black / primary

				SubTitle: '',
				SubTitle2: "",

				bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
				bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

				offset: "20vh",
				height: "65vh",

				overlayOpacity: "0.3",
				overlayColor: "black",
				imgBlur: "0px",

				transitionRotate: "180"

			},

			dialogs: {
				resetPassword: false,
				register: false
			}
		}),
		methods: {
			preSignUp() {
				if (!this.$refs.form2.validate()) return;

				this.dialogs.register = true;
			},

			resetPassword() {
				if (!this.$refs.form2.validate()) return;

				this.$firebase.auth().useDeviceLanguage();

				this.$firebase.auth().sendPasswordResetEmail(this.email).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `Un mail de réinitialisation de mot de passe vous a été envoyé !`
					});
				}).catch((error) => {
					switch (error.code) {
						case "auth/user-not-found":
							this.$store.dispatch('setSnackbar', {
								text: `Cette adresse e-mail n'est liée à aucun compte.`,
								color: 'error'
							});
							break;

						case "auth/too-many-requests":
							this.$store.dispatch('setSnackbar', {
								text: `Pour des raisons de sécurité, votre compte à été bloqué, merci de ressayer plus tard.`,
								color: 'error'
							});
							break;
					}
				});

			},
			async checkOTP() {
				if(!this.otpcode) return;
				
				const otp = String(this.otpcode);
				const secret = this.otpsecret;

				const valid = verify(otp, secret);

				const t = this.$functions.findWithAttr(this.otpbackup, "code", Number(otp));

				if(valid) this.tryLogin();
				else if (t != -1 && this.otpbackup[t].used == false) this.tryLogin(true);
				else this.$store.dispatch('setSnackbar', {
					text: `Code OTP invalide !`,
					color: 'error'
				});
			},
			login() {
				if (!this.$refs.form.validate()) return;

				this.$db.collection("users").where("email", "==", this.email).get().then((res) => {
					res.forEach((doc) => {
						if(doc.data().otp.active) {
							this.needOTP = true
							this.otpsecret = doc.data().otp.secret;
							this.otpbackup = doc.data().otp.backupCodes;
						}
						else this.tryLogin();

					});
				});
			},
			async tryLogin(backup = false) {
				try {
					await this.$firebase.auth().signInWithEmailAndPassword(this.email, this.password);

					if(backup){
						const t = this.$functions.findWithAttr(this.otpbackup, "code", Number(this.otpcode));
						this.otpbackup[t].used = new Date();

						this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).update({
							'otp.backupCodes': this.otpbackup
						});

						this.$store.dispatch('setSnackbar', {
							text: `Vous avez utilisé un code de secours !`,
							color: 'warning'
						});
					}

					this.$store.dispatch('setSnackbar', {
						text: `Vous êtes bien connecté !`
					});

					this.$router.push("/");
				} catch (err) {
					switch (err.code) {
						case "auth/user-not-found":
							this.$store.dispatch('setSnackbar', {
								text: `Cette adresse e-mail n'est liée à aucun compte.`,
								color: 'error'
							});
							break;

						case "auth/wrong-password":
							this.$store.dispatch('setSnackbar', {
								text: `Mot de passe incorrect.`,
								color: 'error'
							});
							break;

						case "auth/too-many-requests":
							this.$store.dispatch('setSnackbar', {
								text: `Pour des raisons de sécurité, votre compte à été bloqué, merci de ressayer plus tard.`,
								color: 'error'
							});
							break;
					}
				}
			}
		},
	}

</script>
