<template>

	<v-form ref="form" lazy-validation style="background-color: transparent;"
		:class="$vuetify.breakpoint.xsOnly ? 'pa-2' : 'pa-8'">

		<v-card elevation="8" class="pt-8 mb-16">

			<v-row class="pa-4">
				<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mx-auto">
					<v-row>
						<v-col cols="12" class="pb-0">
							<Title class="font-weight-light"
								weight="bold"
								titleSize="h4"
							>Vous, ici ?!</Title>
						</v-col>
						<v-col cols="12" class="pt-0">
							<Title weight="regular">Bienvenue {{ customerEntry.firstName }} :)</Title>
						</v-col>

						<v-col cols="12">
							<Title weight="regular" titleSize="subtitle-1">Civilité</Title>

							<v-row>
								<v-col cols="6" md="4" v-for="civility in this.$listUtils.civility" :key="civility">
									<v-btn @click="customerEntry.civility = civility" color="primary" class="neon" :outlined="customerEntry.civility !== civility">{{ civility }}</v-btn>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" sm="6">
							<v-text-field label="Nom" v-model="customerEntry.lastName" :rules="this.$fieldsRules.required('nom')"></v-text-field>
						</v-col>

						<v-col cols="12" sm="6">
							<v-text-field label="Prénom" v-model="customerEntry.firstName" :rules="this.$fieldsRules.required('prénom')"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-menu v-model="menus.customerBirthdate" :close-on-content-click="false" :nudge-left="60"
								transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="formatCustomer" label="Date de naissance" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
									</v-text-field>
								</template>
								<v-date-picker first-day-of-week="1" v-model="customerEntry.birthdate" @input="menus.customerBirthdate = false"></v-date-picker>
							</v-menu>
						</v-col>

						<v-col cols="12">
							<Title weight="regular" titleSize="subtitle-1">Vous êtes :</Title>

							<v-row>
								<v-col cols="12" sm="6">
									<v-btn @click="userPro = false" color="primary" class="neon" :outlined="userPro">Particulier</v-btn>
								</v-col>
								<v-col cols="12" sm="6">
									<v-btn @click="userPro = true" color="primary" class="neon" :outlined="!userPro">Professionnel</v-btn>

								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<v-row class="pa-4">
				<v-col cols="12">
					<Title weight="bold" titleSize="h4">Comment peut-on vous contacter ?</Title>
				</v-col>

				<v-col cols="12">
					<Title weight="bold" titleSize="caption">Vos informations ne seront jamais transmisent à des tiers, jamais.</Title>
				</v-col>

				<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mx-auto">
					<v-text-field label="N° de Téléphone" v-model="customerEntry.phone" maxlength="10"></v-text-field>
					
					<v-text-field label="Site web" v-model="customerEntry.website" prefix="https://"></v-text-field>
				</v-col>
			</v-row>

			<v-row class="pa-4" v-if="userPro">
				<v-col cols="12">
					<Title weight="bold" titleSize="h4">Votre entreprise</Title>
				</v-col>

				<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mx-auto">
					<v-row>
						<v-col cols="12" class="pb-0">
							<v-text-field label="SIRET" type="number" hint="SIRET de votre entreprise" v-model="companyEntry.siret"></v-text-field>
						</v-col>
						<v-col cols="12" class="pt-0">
							<v-btn class="neon" outlined color="primary" @click="addCompanyBySIRET">
								Trouver mon entreprise !
							</v-btn>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="pb-0">
							<Title weight="regular" titleSize="subtitle-1">Identité</Title>
						</v-col>
						<v-col cols="12" md="12">
							<v-text-field label="Nom" hint="Nom de l'entreprise" v-model="companyEntry.name"></v-text-field>
							
							<v-menu v-model="menus.companyBirthdate" :close-on-content-click="false" :nudge-left="60" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="formatCompanyDate" label="Date de création" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
									</v-text-field>
								</template>
								<v-date-picker first-day-of-week="1" v-model="companyEntry.birthdate" @input="menus.companyBirthdate = false"></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" class="pb-0">
							<Title weight="regular" titleSize="subtitle-1">Adresse</Title>
						</v-col>

						<v-col cols="12" class="py-0">
							<v-text-field label="Adresse" hint="Adresse de l'entreprise" v-model="companyEntry.address.street"></v-text-field>
						</v-col>

						<v-col cols="12" sm="6" class="py-0">
							<v-text-field label="Code postal" hint="Code postal de l'entreprise" v-model="companyEntry.address.postalCode"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" class="py-0">
							<v-text-field label="Ville" hint="Ville de l'entreprise" v-model="companyEntry.address.city"></v-text-field>
						</v-col>

						<v-col cols="12" sm="6" class="py-0">
							<v-text-field label="Region" hint="Region de l'entreprise" v-model="companyEntry.address.region"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" class="py-0">
							<v-text-field label="Pays" hint="Pays de l'entreprise" v-model="companyEntry.address.country"></v-text-field>
						</v-col>
					</v-row>

					<v-row>

						<v-col cols="12" class="pb-0">
							<Title weight="regular" titleSize="subtitle-1">Contact</Title>
						</v-col>
						<v-col cols="12" sm="12">
							<v-text-field label="N° de téléphone" hint="N° de téléphone de l'entreprise" v-model="companyEntry.phone" maxlength="10">
							</v-text-field>
							<v-text-field prefix="https://" label="Site web" hint="Lien du site Web de l'entreprise" v-model="companyEntry.website">
							</v-text-field>
							<v-text-field label="Adresse mail" hint="Adresse emil de l'entreprise" v-model="companyEntry.email">
							</v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<v-card-title>
				<v-spacer></v-spacer>
				<v-btn class="ma-2 mt-4" outlined :color="$vuetify.theme.dark ? 'white' : 'black'">
					M'inscrire !
				</v-btn>
				<v-spacer></v-spacer>
			</v-card-title>

		</v-card>
	</v-form>
</template>

<script>
	import Title from '@/components/graphics/title';

	export default {
		name: "RegisterComponent",
		components: {
			Title,
		},
		data() {
			return {
				siret: '',

				activeStep: 1,

				compStep: {
					small: "Optionnel"
				},

				customerEntry: {
					...this.$models.user,
					gender: "Monsieur"
				},
				companyEntry: this.$models.company,

				userPro: true,

				menu: false,

				menuCustomerBirthdate: false,

				menus: {
					customerBirthdate: false,
					companyBirthdate: false
				},

				step1Rules: [
					v => !!v || true
				],
				step2Rules: [
					v => !!v || true
				]
			}
		},
		computed: {
			formatCustomer() {
				return this.customerEntry.birthdate ? this.$moment(this.customerEntry.birthdate).format('DD/MM/YYYY') : ''
			},
			formatCompanyDate() {
				return this.companyEntry.birthdate ? this.$moment(this.companyEntry.birthdate).format('DD/MM/YYYY') : ''
			}
		},
		watch: {
			activeStep: function () {
				switch (this.activeStep) {
					case 2:
						if (this.customerEntry.civility && this.customerEntry.lastName && this.customerEntry
							.firstName && this.customerEntry.email && this.customerEntry.password && (this
								.customerEntry.password == this.customerEntry.confPassword)) {
							this.step1Rules = [() => true];
						} else {
							this.step1Rules = [() => false];
						}

						break;
				}
			}
		},
		methods: {
			acceptNumber() {
				var x = this.customerEntry.phone.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/);
				this.customerEntry.phone = x[1] + " " + x[2] + " " + x[3] + " " + x[4] + " " + x[5]
			},
			save(date) {
				this.$refs.menu.save(date);
			},
			async submit() {
				if (!this.$refs.form.validate()) {
					return this.$store.dispatch('setSnackbar', {
						text: `Tous les champs n'ont pas été remplis correctement.`,
						color: 'error'
					});
				}

				try {
					this.$firebase.auth().createUserWithEmailAndPassword(this.customerEntry.email, this.customerEntry
						.password).then((cred) => {
						try {
							cred.user.updateProfile({
								displayName: this.customerEntry.firstName + ' ' + this.customerEntry
									.lastName,
								photoURL: this.customerEntry.avatar
							});

							const client = {
								...this.customerEntry,

								'creationDate': new Date(),
								'birthdate': new Date(this.customerEntry.birthdate),

								'status': 3,

								'notification': {
									'sync': {
										'mail': false,
										'app': false
									},
									'quote': {
										'mail': false,
										'app': false
									}
								}
							};

							delete client.password;
							delete client.confpassword;

							this.$db.collection('users').doc(cred.user.uid).set(client).then(() => {
								console.log(`Création de l'utilisateur n°${cred.user.uid}`);

								if (this.companyEntry.siret) {
									const entreprise = {
										...this.companyEntry,

										'creationDate': new Date(),
										'birthdate': new Date(this.companyEntry.birthdate),

										'belongTo': cred.user.uid,

										'status': 3,
									};

									this.$db.collection('companies').add(entreprise).then(docRef => {
										console.log(`Création de l'entreprise n°${docRef.id}`);

										this.$db.collection('users').doc(cred.user.uid).set({
											'company': docRef.id
										}, {
											merge: true
										});

										this.$katana.addUserToCompany(cred.user.uid, docRef.id, 1);
										
										console.log(`Ajout de l'entreprise ${docRef.id} à l'utilisateur ${cred.user.uid}`);
									});
								}
							}).then(() => {
								this.$store.dispatch('setSnackbar', {
									text: `Votre compte à bien été créé, vous êtes maintenant connecté.`
								});

								this.$refs.form.resetValidation();
							});
						} catch (err) {
							this.$store.dispatch('setSnackbar', {
								text: `Erreur: ${err}.`,
								color: 'error'
							});
						}
					}).catch((err) => {
						this.$store.dispatch('setSnackbar', {
							text: `Erreur: ${err}.`,
							color: 'error'
						});
					});
				} catch (err) {
					console.log(err);
				}
			},
			async addCompanyBySIRET() {
				var config = {
					method: 'get',
					url: 'https://api.insee.fr/entreprises/sirene/V3/siret/' + this.companyEntry.siret,
					headers: {
						'Accept': 'application/json',
						'Authorization': 'Bearer f39df87b-c8a7-3176-8b7b-eac78bb8c190'
					},
					data: {
						'quantity': '1',
						'offset': '0'
					}
				};

				this.$axios(config).then((response) => {
					var comp = response.data.etablissement;

					this.compStep.color = "success";
					this.compStep.small = "SIRET Vérifié !";
					this.companyRules = [() => true];

					this.companyEntry.birthdate = this.$moment(comp.uniteLegale.dateCreationUniteLegale).format('YYYY-MM-DD');

					if (comp.uniteLegale.denominationUniteLegale) {
						this.companyEntry.name = comp.uniteLegale.denominationUniteLegale;
					} else {
						var sexe = comp.uniteLegale.sexeUniteLegale == "F" ? "Mme" : "M";
						this.companyEntry.name = sexe + " " + comp.uniteLegale.prenom1UniteLegale + " " + comp
							.uniteLegale.nomUniteLegale;
					}

					var config2 = {
						method: "get",
						url: "https://api-adresse.data.gouv.fr/search/?q=" + comp.adresseEtablissement
							.numeroVoieEtablissement + "+" + comp.adresseEtablissement
							.typeVoieEtablissement + "+" + comp.adresseEtablissement
							.libelleVoieEtablissement + "&postcode=" + comp.adresseEtablissement
							.codePostalEtablissement,
						headers: {
							'Accept': 'application/json',
						},
						data: {
							'quantity': '1',
							'offset': '0'
						}
					}

					this.$axios(config2).then((response) => {
						var address = response.data.features[0].properties;

						this.companyEntry.address.street = address.name;
						this.companyEntry.address.postalCode = address.postcode;
						this.companyEntry.address.city = address.city;
						this.companyEntry.address.country = "France";

						var splitContext = address.context.split(", ");
						this.companyEntry.address.region = splitContext[1];
					});
				}).catch(err => {
					err;

					this.step2Rules = [() => false];
					this.compStep.small = "SIRET Introuvable !";
				});
			}
		}
	}
</script>